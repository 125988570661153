:root {
    --speed-multiplier: 1.5;
}

.object{
    position:absolute ;
    overflow: hidden;
    width:100%;
    height:100%;
    padding-top: 0px;
    padding-bottom: 0px;
}
.object div{
    position:absolute ;
    display:block ;
    
}
.object div:nth-child(1){
    left:20%; 
    animation:fall calc(15s / var(--speed-multiplier)) linear infinite ;
    animation-delay:-2s;
}
.object div:nth-child(2){
    left:70%; 
    animation:fall calc(15s / var(--speed-multiplier)) linear infinite ;
    animation-delay:-4s;
}
.object div:nth-child(3){
    left:10%; 
    animation:fall calc(20s / var(--speed-multiplier)) linear infinite ;
    animation-delay:-7s;
    
}
.object div:nth-child(4){
    left:50%; 
   animation:fall calc(18s / var(--speed-multiplier)) linear infinite ; 
   animation-delay:-5s;
}
.object div:nth-child(5){
    left:85%; 
    animation:fall calc(14s / var(--speed-multiplier)) linear infinite ;
    animation-delay:-5s;
}
.object div:nth-child(6){
    left:15%; 
    animation:fall calc(16s / var(--speed-multiplier)) linear infinite ;
    animation-delay:-10s;
}
.object div:nth-child(7){
    left:90%; 
    animation:fall calc(15s / var(--speed-multiplier)) linear infinite ;
    animation-delay:-9s;
}

@keyframes fall{
    0%{
        top:-18%;
        transform:translateX (20px) rotate(0deg);
    }
    20%{
        transform:translateX (-20px) rotate(45deg);
    }
    40%{

        transform:translateX (-20px) rotate(90deg);
    }
    60%{
        
       transform:translateX (-20px) rotate(135deg); 
    }
    80%{
    
        transform:translateX (-20px) rotate(180deg);
    }
    100%{
        
        top:110%;
        transform:translateX (-20px) rotate(225deg);
    }
}
